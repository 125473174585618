<template>
  <div>
    <div
      class="d-flex justify-content-center mb-1"
      style="padding-top: 50vh"
    >
      <span class="font-large-1">Chargement...</span>&nbsp;
      <b-spinner />
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  // BButton
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    // BButton,
    BSpinner,
  },
  mounted() {
    this.applyVerifyInvitationAction()
  },
  methods: {
    ...mapActions('users', ['verifyInvitationAction']),
    applyVerifyInvitationAction() {
      this.verifyInvitationAction(this.$route.params.token)
        .then(() => {
          localStorage.setItem('invitationToken', this.$route.params.token)
          this.$router.push({ name: 'register' })
        })
        .catch(() => {
          this.$swal({
            title: 'Erreur',
            text: 'Invitation expirée ou lien invalide',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
          })
        })
    },
  },
}
</script>

<style>
</style>
